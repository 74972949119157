import React from "react";

const PaginationNextIcon = ({ className }) => {
  return (
    <svg
      viewBox="0 0 9 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Apple-TV-Copy-5"
          transform="translate(-1197.000000, -3166.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="Group-7" transform="translate(696.000000, 3149.000000)">
            <g
              id="down-chevron-copy-9"
              transform="translate(505.500000, 24.000000) scale(-1, 1) rotate(-270.000000) translate(-505.500000, -24.000000) translate(499.000000, 20.000000)"
            >
              <polygon
                id="Path"
                points="10.8390244 0.0435360017 6.14400024 4.73856019 1.44897606 0.0435360017 0 1.49246406 6.14400024 7.6364643 12.2880005 1.49246406"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PaginationNextIcon;
